import styled from "styled-components";
import ResultPageCookies from "./ResultPage";
import {primaryFontColor, secondaryFontColor} from "../../app/layout/GlobalStyles.styles";

export const StyledResultPage = styled(ResultPageCookies)`
        width: 100% !important;
        margin: 0 auto !important;
        padding: 0 20px;

.loading-dimmer,
.ui.dimmer{
background-color: transparent !important;
}
.loading-loader{
background-color: rgba(0,0,0,.5);
padding: 40px;
}

.loading-loader:before,
.loading-loader:after{
margin-top: 10px!important;
}
.loading-loader h2{
font: normal normal normal 1.4rem/2rem Futura Book !important;
letter-spacing: 0 !important;
margin-bottom:  0 !important;
}
.loading-loader p {
font: normal normal normal 1.5rem Futura Light;
letter-spacing: 0px;
}

.overlay{
    position: fixed;
    top: 0;
    left: 0;
    z-index:9;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.8);
}

.information-container{
    background-color: white;
    padding: 80px 82px 80px 94px;
    margin:0 auto;
    max-width: 1021px;
    margin-top: 189px;
}

.information-container h1,
.information-container p {
    color: ${secondaryFontColor};
}
.information-container h1{
    font: normal normal normal 2.7rem Futura Light;
    letter-spacing: 0px;
    text-transform: uppercase;
}
.information-container p{
font: normal normal bold 1.4rem/2rem Futura Light;
white-space: pre-wrap;
letter-spacing: 0px;
}

    .result-intro{
        text-align: center;
        max-width: 1100px;
        margin: 0 auto 28px auto;
    }
    
    .result-intro h1 {
        font: normal normal normal 2.7rem Futura Book;
        letter-spacing: 2.4px;
        color: ${primaryFontColor};
        opacity: 1;
        text-transform: uppercase;
    }
    
    .result-intro p {
        font: normal normal normal 1.4rem/2rem Futura Light;
        letter-spacing: 0px;
        color: ${primaryFontColor};
    }
    
    
    .result-container button{
        margin-top: 20px;
        margin-right: 20px;
    }
   
`;