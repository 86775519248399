import React, {useEffect, useState} from 'react';
import {WordAnnotation} from "../../app/models/OPSResult";
import agent from "../../app/api/agent";
import {AxiosResponse} from "axios";
import {AISetting} from "../../app/models/AISetting";

interface WordAnnotationContainerProps{
    originalText : string;
    wordAnnotations : WordAnnotation[];
    className : string;
}
export default function WordAnnotationContainer(props : WordAnnotationContainerProps){
    
    function getStyledOriginalText(originalText : string):JSX.Element{
        let startTag : string = '<span>'
        let endTag : string = "</span>"
        
        let styledOriginalText = originalText;        
        for(let i = props.wordAnnotations.length-1; i >= 0; i--){
            
            // insert span end tag
            let endPosition = props.wordAnnotations[i].end;
            styledOriginalText = [styledOriginalText.slice(0, endPosition), endTag, styledOriginalText.slice(endPosition)].join('');
            // insert span start tag
            let startPosition = props.wordAnnotations[i].begin;
            styledOriginalText = [styledOriginalText.slice(0, startPosition), startTag, styledOriginalText.slice(startPosition)].join('');
        }
        return <div className={props.className} dangerouslySetInnerHTML={{ __html: styledOriginalText }} />;
    }
    return (<>
        {getStyledOriginalText(props.originalText)}
    </>);
} 
