import styled from "styled-components";
import HomePage from "./HomePage";
import {device, primaryFontColor, primarySymbolColor, secondaryFontColor} from "../../app/layout/GlobalStyles.styles";

export const styledHomePage = styled(HomePage)`
    .home-container{
        width: 100% !important;
        margin: 0 auto !important;
    }
    
    padding: 0 20px;
    
    #rico-header-text h1 {
        font: 300 9rem Futura Light !important;
        margin: 0;
        vertical-align: bottom;
        display: inline-block;
        height: 1em;
    }
    
    #rico-header{
        margin-bottom: 50px;
    }
    
    #intro-container {
        border: 1px solid ${primaryFontColor};
        padding: 68px 50px 51px 50px;
    }
    
    #intro-text {
        text-align: center;
        font: normal normal 300 2rem Futura Light;
        letter-spacing: 0px;
        color: ${primaryFontColor};
        width: 70%;
        margin: 0 auto;
        padding-bottom: 50px
    }
    
    #rico-header{
    text-align: center;
    }
    
    #rico-header-text {
        color: ${primaryFontColor};
        position:relative;
        display:inline-block;
        top: -0.5em;
    }
    #rico-header span {
        height: 1em;
        vertical-align: bottom;
        display: inline-block;
    }
    
    #rico-header-subtext {
        font: normal normal 300 1.5em Futura Light;
        letter-spacing: 0px;
        color: ${primaryFontColor};
        position:absolute;
        bottom:0;right:0;
        transform:translateX(100%)
    }
    
    .steps-number {
      width: 48px;
      height: 48px;
      font: normal normal bold 24px/34px Futura Book;
      letter-spacing: 0px;
      line-height: 48px;
      border-radius: 50%;
      color: ${secondaryFontColor};
      text-align: center;
      background: ${primarySymbolColor};
      margin: 0 auto;
    }
    
    .step-text {
        font: normal normal normal 1.5rem Futura Light;
    }
   
    .steps-container{
        display: flex;
        justify-content: space-between;
    }
    
    .steps-container.top{
        padding-bottom: 28px;
    }
    
        .steps-container.bottom{
        padding-bottom: 50px;
    }
    
    .steps-number-container{
        width: 200px;
    }

    
    .steps-space{
        flex-grow:1;
    }
    
    .horizontal-line{
        height: 50%;
        border-bottom: 1px dashed ${primaryFontColor};
    }
    
    .step-text{
        width: 280px;
        text-align: center;
    }  
    
    .steps-number-container.left{
        padding-left: 80px;
        margin-right: 30px;
    }
    
    .steps-number-container.center{
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .steps-number-container.right{
        padding-right: 80px;
         margin-left: 30px;
    }
    
    .centered-button {
        margin: 0 auto !important;
      
    }
    
    .button-container {
        text-align: center;
    }
    
    .mobile-view{
      display: none;
    }
    
    @media ${device.tablet} {
    
        #rico-header-text h1 {
            font-size: 6rem !important;
        }
        
        #intro-container {
            padding: 15px 50px;
        }  
        
         .steps-number-container.left{
        padding-left: 80px;
        margin-right: 30px;
    }
    
    .steps-number-container.center{
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .steps-number-container.right{
        padding-right: 80px;
         margin-left: 30px;
    }
        
        #intro-text {
            font-size: 1.6rem;
        }
        
        .step-text {
            font-size: 1.2rem;
        }
    }
    
          @media ${device.phone} {
             #intro-container {
                padding: 15px 5px;
            }  
            #rico-header-subtext {
                position:static;
                transform:none;
                display: block;
            }
            #rico-header-text {
                position:static;
                display: block;
            }
            
            #rico-header span {
                display: block;
            }
            
            #intro-text {
                font-size: 1rem;
                width: 90%;
            }
            
            .desktop-view{
                display: none;
            }
            .mobile-view{
                display: block;
                margin-bottom: 55px;
            }
            
            .step-text {
                width: auto;
            }
            
            .vertical-line{
                width: 50%;
                height: 35px;
                margin: 20px 0;
                border-right: 1px dashed ${primaryFontColor};
            }
            
            .steps-number{
                margin: 0 auto 12px auto;
            }
            
            .button-container {
                margin: 0px 25px 25px 25px;
            }
            
            
        }
`;