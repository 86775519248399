import React, {useState} from 'react';
import {Button, Container} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import RegisterForm from "./components/RegisterForm";
import Disclaimer from "./components/Disclaimer";
import LoginForm from "./components/LoginForm";
import { useHistory } from 'react-router-dom';
import {Cookies, withCookies} from "react-cookie";
import {StyledDisclaimer} from "./components/Disclaimer.style";

interface SignupPageProps {
    className? : string;
    cookies : Cookies
}

function SignupPage(props : SignupPageProps){
    
    
    const [resultDisclaimer, setResultDisclaimer] = useState(false);
    const [openDisclaimerPopup, setOpenDisclaimerPopup] = useState(true);
    
    
    function changeResultPrivacyPolicy(result : boolean){
        setResultDisclaimer(result);
    }
    
    function showPrivacyPolicy(){
        setOpenDisclaimerPopup(true);
    }
    
    function openDisclaimer(value : boolean) {
        setOpenDisclaimerPopup(value);
    }
    const history = useHistory();
    
    return(
        <Container className={props.className + " signup-container"}>
            <StyledDisclaimer open={openDisclaimerPopup} setOpen={setOpenDisclaimerPopup} result={resultDisclaimer} setResult={setResultDisclaimer} />
            <div className={"signup-intro"}>
                <h1>Anmeldung</h1>
                <p>Wir wissen, dass viele an der OPS-KI interessiert sind, aber die kostenlose OPS-KI der RICO community edition steht ausschließlich Krankenhäusern zur Verfügung ;-)</p>
            </div>
            <div className={"form-container"}>
                <RegisterForm  resultDisclaimer={resultDisclaimer} showDisclaimer={setOpenDisclaimerPopup}/>
                <LoginForm resultDisclaimer={resultDisclaimer} showDisclaimer={setOpenDisclaimerPopup} />
            </div>
        </Container>)
}

let SignupPageCookies =  withCookies(SignupPage)
export default SignupPageCookies;
