import styled from "styled-components";
import SignupPage from "./SignupPage";
import {
    device,
    primaryButton,
    primaryFontColor,
    primarySymbolColor,
    secondaryFontColor
} from "../../app/layout/GlobalStyles.styles";
import SignupPageCookies from "./SignupPage";

export const StyledSignupPage = styled(SignupPageCookies)`

        width: 100% !important;
        margin: 0 auto !important;
        padding: 0 20px;

        
    .orange,
    {
        color: ${primarySymbolColor};
    }
    
    .error {
        color: red;
    }
    
    .signup-intro{
        text-align: center;
        max-width: 1100px;
        margin: 0 auto 28px auto;
    }
    .signup-intro h1 {
        font: normal normal normal 2.7rem Futura Book;
        letter-spacing: 2.4px;
        color: ${primaryFontColor};
        opacity: 1;
        text-transform: uppercase;
    }
    
    .signup-intro p {
        font: normal normal normal 1.4rem/2rem Futura Light; //24
        letter-spacing: 0px;
        color: ${primaryFontColor};
    }
    
    h2 {
        font: normal normal normal 2rem Futura Light; //34
        letter-spacing: 0px;
        color: ${primaryFontColor};
        margin-bottom: 20px;
    }
    
    .form-container{
        display: flex;
        flex-direction : row;
        justify-content: center;
        align-items: stretch;
    }
    
    .login-container{
    padding-left: 100px;
    width:100%;
    }
    
    .register-container{
    width:100%;
    }
    
    .register-border-container{
        padding-right: 100px;
        border-right: 1px solid ${primaryFontColor};
    }
    
    .text-input{
    border-radius:0  !important;
    margin-top: 9px !important;
    max-width: 639px;
    min-width: 200px;
    min-height: 50px;
    }
    
    label{
    text-transform: uppercase;
    font: normal normal normal 1rem Futura Book;
    letter-spacing: 0.1em;
    }
    
    .privacy-policy-label{
        text-transform: none;
    }
    
    a{
    color: ${primarySymbolColor};
    }
    
    .${primaryButton}{
    margin-top: 0px;
    }
    
    .form-control{
        margin-bottom: 20px !important;
    }
    
    input[type="checkbox"]{
        border-radius: 0 !important;
        border: none;
        height: 20px;
        width: 20px;
    }
    
    @media ${device.tablet} {
        .register-border-container {
            padding-right: 20px;
        }
        .login-container {
            padding-left: 20px;
        }
        
        h2 {
            font-size: 1.7rem;
        }
         .signup-intro p {
            font-size: 1.2rem
        }
        .signup-intro h1 {
            font-size: 2rem;
        }
        .signup-intro {
            margin-bottom: 14px;
        }
        .form-control {
           margin-bottom: 4px !important;
        }
    }
    
     @media ${device.phone} {
        .form-container{
            display: flex;
            flex-direction : column;
            justify-content: flex-start;
            align-items: stretch;
            padding-bottom: 20px;
        }
        
        .${primaryButton}{
            margin: 20px auto 0 auto;
            display: block;
            width: 270px;
        }

        
        .login-container{
            padding 0;
        }
        
        label{
            display: block;
        }
        
        .text-input{
            max-width: 100%;
            min-width: 100%;
            min-height: 50px;
            margin-bottom: 20px !important;
        }
        
        .register-border-container{
            padding: 0;
        }
        
        .register-border-container{
        padding-right: 0;
        border-right: none;
        }
        
        .register-container{
            padding-bottom: 50px;
            border-bottom : 1px solid ${primaryFontColor};
        }
        .login-container{
            padding-top: 50px;
        }
        
        .privacy-policy-label{
            display: inline;
            font-size: 0.8rem;
        }

    }
`;
