import React from 'react';
import {ErrorMessage, Form, Field} from "formik";
import TextError from "./TextError";
import {Checkbox} from "semantic-ui-react";

export default function Input(props: any) {
    const {label, name, ...rest} = props;

    return(
        <div className='form-control'>
                <Field id={name} name={name} {...rest} />
                {label}
                <ErrorMessage name={name} component={TextError} />
       </div>
    );
}