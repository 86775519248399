import React from 'react';
import {Container, Menu, Item, Button} from "semantic-ui-react";

import GSGLogo from '../images/Logo_GSG.png';
import RICOLogo from '../images/Logo_RICO.png';
import FraunhoferLogo from '../images/Logo_Fraunhofer.png';
import {useHistory, useLocation} from "react-router-dom";


interface HeaderBarProps {
    className? : string;
}
export default function HeaderBar(props : HeaderBarProps){

    const history = useHistory();
    const location = useLocation();
    function navigateTo(path : string){
        history.push(`/${path}`);
    }
    
    return (
    <div className={props.className + " header-bar"}>
        <a href={"https://right-coding.com/"} target={"_blank"}><img src={GSGLogo} className={"img-logo"} alt="GSG Logo"/></a>
        <a style={{display: location.pathname == "/" ? "none": "inline"}} href={"#"} onClick={()=>{navigateTo("");}} className={"rico-logo"}><img   src={RICOLogo} alt="GSG Logo"/></a>
        <a href={"https://right-coding.com/"} target={"_blank"} className={"fraunhofer-logo"}><img src={FraunhoferLogo} className={"img-logo"}  alt="Fraunhofer IAIS"/></a>
    </div>
    )
}