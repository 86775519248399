import styled from "styled-components";
import AnalysisPageCookies from "./AnalysisPage";
import {device, primaryFontColor} from "../../app/layout/GlobalStyles.styles";

export const StyledAnalysisPage = styled(AnalysisPageCookies)`
        width: 100% !important;
        margin: 0 auto !important;
        padding: 0 20px;
        
    .analysis-intro{
        text-align: center;
        max-width: 1100px;
        margin: 0 auto 28px auto;
    }
    .error {
        color: red;
    }
    
    .form-control{
        margin-bottom: 20px !important;
    }
    .analysis-intro h1 {
        font: normal normal normal 2.7rem Futura Book;
        letter-spacing: 2.4px;
        color: ${primaryFontColor};
        opacity: 1;
        text-transform: uppercase;
    }
    
    .analysis-intro p {
        font: normal normal normal 1.4rem/2rem Futura Light;
        letter-spacing: 0px;
        color: ${primaryFontColor};
    }
    
    .analysis-textarea{
        border-radius:0  !important;
        margin-top: 9px !important;
        margin-bottom: 4px !important;
        padding: 48px 63px 48px 38px !important;
        font: normal normal normal 1.4rem/1.6 Futura Light !important;
        letter-spacing: 0px !important;
        font-weight: bold !important;
        min-height: 500px !important;
        
    }
    
    label{
        font: normal normal normal 1rem Futura Book !important;
        letter-spacing: 1.28px !important;
    }
    
    .error-message{
        text-align:center;
        margin-top: 50px;
        font: normal normal normal 1.2rem Futura Book !important;
    }
    
    .remaining-analyses{
        margin: 0;
    }
    
     @media ${device.tablet} {
     {
        padding-bottom: 20px;
     }
         .analysis-intro p {
            font-size: 1.2rem
        }
        .analysis-intro h1 {
            font-size: 2rem;
        }
        .analysis-intro {
            margin-bottom: 14px;
        }
        .form-control {
           margin-bottom: 4px !important;
        }
        
        .analysis-textarea{
            min-height: 400px !important;    
        }
    }
    
    @media ${device.phone} {
        
    }
`; 
