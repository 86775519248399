import { useHistory, useParams, Link } from 'react-router-dom'
import {useEffect, useState} from "react";
import agent from "../app/api/agent";
import { Formik, Field, Form } from 'formik';
import {Cookies, withCookies} from "react-cookie";
import * as Yup from "yup";
import {primaryButton} from "../app/layout/GlobalStyles.styles";

interface CookieConsentProps {
    className? : string;
    cookies : Cookies
}

function CookieConsent(props : CookieConsentProps){

    const [isVisible, setIsVisible] : [boolean, Function] = useState(false);
    const [isSettingsVisible, setIsSettingsVisible] : [boolean, Function] = useState(false);

    interface FormData {
        checkedStatistics : string[],

    }
    
    const initialValues : FormData= {
        checkedStatistics : [],
    };


    useEffect(() => {
        //1. check if consent window should be visible or not, by checking if user has already accepted a setting
        let functionalCookies = props.cookies.get("functional-cookies");
        let statisticsCookies = props.cookies.get("statistics-cookies");
        if (functionalCookies || statisticsCookies) {
            //user already accepted a setting
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }        
    }, []);
    
    
    const onSubmit = (values:FormData, test:any) => {
        if(values.checkedStatistics.includes("statistics")){
            storeCookie("statistics-cookies", true);
            storeCookie("functional-cookies", true);
        } else {
            storeCookie("functional-cookies", true);
        }
        setIsVisible(false);

    };

    function storeCookie(name: string, value: any) {
        let currentDate = new Date();
        let monthFutureDate = new Date(currentDate);
        monthFutureDate.setDate(monthFutureDate.getDate() + 30)
        props.cookies.set(name, value, {expires : monthFutureDate});
    }
    function acceptFunctionalOnly() {
        // close this popup and don't open it again
        storeCookie("functional-cookies", true);
        setIsVisible(false);
    }
    
    function acceptAll() {
        storeCookie("functional-cookies", true);
        storeCookie("statistics-cookies", true);
        setIsVisible(false);
    }
    
    return(
        <div className={props.className} style={{display : isVisible ? "block" : "none"}}>
            <div className={"cookie-container"} style={{display : isVisible ? "block" : "none"}}>
                <div className={"cookie-consent-description"}>Wir verwenden Cookies, um unsere Website und unseren Service zu optimieren. <Link to={"/cookiepolicy"} target={"_blank"}>Weiterlesen</Link> - <a href={"https://gsg-online.com/impressum/"} target={"_blank"}>Impressum</a></div>
                <Formik validateOnChange={false} validateOnBlur={false} inverted initialValues={initialValues} onSubmit={onSubmit}>
                    {formik => {
                    
                    return <form onSubmit={formik.handleSubmit}>
                                    <label style={{display : isSettingsVisible ? "block" : "none"}}>
                                        <Field checked={true} disabled={true} type="checkbox" value="functional" />
                                        Funktional
                                    </label>
                                    <label style={{display : isSettingsVisible ? "block" : "none"}}>
                                        <Field type="checkbox" name="checkedStatistics" value="statistics" />
                                        Statistiken
                                    </label>
                                <button type={"button"} className={primaryButton + " accept-all-button"} name={"acceptAll"} onClick={acceptAll}>Alle Akzeptieren</button>
                                <button type={"button"} className={"non-styled-button"} name={"acceptFunctional"} onClick={acceptFunctionalOnly}>Nur funktionsfähig</button>
                                <button type={"button"} style={{display : isSettingsVisible ? "none" : "inline"}} className={"non-styled-button"} name={"showSettings"} onClick={()=>{setIsSettingsVisible(true)}}>Einstellungen anzeigen</button>
                                <button style={{display : isSettingsVisible ? "inline" : "none"}} className={"non-styled-button"} name={"saveSettings"} type={"submit"}>Einstellungen speichern</button>
                            </form>
                    }}
                    
                </Formik>
    
    
            </div>
        </div>
    );
}


let CookieConsentCookies =  withCookies(CookieConsent)
export default CookieConsentCookies;
