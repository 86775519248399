import styled from "styled-components";
import Disclaimer, {DisclaimerProps} from "./Disclaimer";
import {
    device,
    primaryFontColor,
    primarySymbolColor, primarySymbolColorHover,
    secondaryFontColor,
    secondarySymbolColor, secondarySymbolColorHover
} from "../../../app/layout/GlobalStyles.styles";


export const StyledDisclaimer = styled(Disclaimer)`
        
        {
            padding: 20px 44px 30px 48px;
            border-radius: 0 !important;
            width: 862px !important;
        }
        
       .header{
        color: ${secondaryFontColor} !important;
        background-color: ${primaryFontColor} !important;
        border-bottom: none !important;  
        border-radius: 0 !important; 
        font: normal normal normal 2.5rem Futura Light !important;
        letter-spacing: 0px !important;
        font-weight: bold !important;
        white-space: pre-wrap !important;

       }
       .content{
        color: ${secondaryFontColor} !important;
        background-color: ${primaryFontColor} !important;  
        font: normal normal normal 1.3rem/1.4rem Futura Book!important;
        letter-spacing: 0px; 
        white-space: pre-wrap !important;
       }
       .actions{
        border-top: none !important;
        border-radius:0  !important;
        color: ${secondaryFontColor} !important;
        background-color: ${primaryFontColor} !important;  
        display:flex !important;
        flex-direction: row-reverse !important; 
        justify-content: flex-end !important;
        padding: 1rem 1.5rem !important;
       }
      
       .button{
               color: ${primaryFontColor} !important;
               background-color: ${secondarySymbolColor} !important;
               font: normal normal normal 1.2rem Futura Book !important;
               letter-spacing: 1.2px !important;
               text-transform: uppercase !important;   
               border-radius:0  !important;
               padding: 15px 32px !important;
               border : none !important;
               margin-left : 0 !important;
       }
       
       .button.primary{
               color: ${primaryFontColor} !important;
               background-color: ${primarySymbolColor} !important;
               align-self: flex-end !important;  
               margin-right: 31px;
       } 
       
     .button:hover{
        background-color: ${secondarySymbolColorHover} !important;
    }
    
    .button:active{
        transform: scale(0.98);
    }
       
           
    .button.primary:hover{
        background-color: ${primarySymbolColorHover} !important;
    }
    
    .button.primary:active{
        transform: scale(0.98);
    }
    
     @media ${device.tablet} {
        {
            width: 90% !important;
        }
     }
     
     @media ${device.phone} {
        {
            width: 90% !important;
            padding: 20px 5px 30px 5px;
            box-sizing: border-box;
            margin: 0 !important;
        }
        .header{
            font: normal normal normal 1.5rem Futura Light !important;
        }
        
        .button{
            font: normal normal normal 1rem Futura Book !important;
            padding: 8px 12px !important;
       }
        
     }
       
`;