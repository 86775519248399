import styled from "styled-components";
import HeaderBar from "./HeaderBar";
import {device} from "./GlobalStyles.styles";

export const StyledHeaderBar = styled(HeaderBar)`

      background-color: transparent !important;
      display: flex; 
      align-items: flex-start !important;
      justify-content: flex-start;
      position: static !important;
      padding: 20px 20px 0 20px !important;
    
    img {
     height: 44px;
    }
      
    .rico-logo img{
    height: 75px;
    margin-left: 16px;
    }
    
    .fraunhofer-logo{
    margin-left: auto;
    }
    
      @media ${device.phone} {
       {
         padding: 40px 40px 0 40px !important;
         margin-bottom: 30px;
       }
       
        .img-logo {
         width: 80px;
         height: auto;
       }
       
       .rico-logo img{
         margin-top: 15px;
       }
      }
   `;