import React, {SyntheticEvent, useEffect, useState} from "react";
import {OPSResult} from "../../app/models/OPSResult";
import {Accordion, Icon, Popup} from "semantic-ui-react";
import {Cookies, useCookies} from "react-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import WordAnnotationContainer from "./WordAnnotationContainer";
import agent from "../../app/api/agent";
import {AxiosResponse} from "axios";
import {AISetting} from "../../app/models/AISetting";
import {OPSCode} from "../../app/models/OpsCode";
import {StyledRatingOption} from "./RatingOption.style";

interface OPSCodesProps{
    className?: string;
    opsResult : OPSResult;
}
export default function OPSCodes(props : OPSCodesProps){

    const [activeIndex, setActiveIndex] = useState(0);
    const [opsTexts, setOPsTexts] : [string[], Function]= useState([]);

    function onClick(e: SyntheticEvent, index : any){
        const clickedIndex = index.index;
        const newIndex = activeIndex == clickedIndex ? -1 : clickedIndex;
        setActiveIndex(newIndex);
    }
    
    const OPSCodeAccordionItems = props.opsResult.opsCodes.map((opsCode) => {
        return (<div key={opsCode.id}>
            <Accordion.Title className={"accordion-title"} active={activeIndex === opsCode.id}
                             index={opsCode.id}
                             onClick={onClick}>
                <Icon name='dropdown' />
                {((opsCode.id??0) +1)} von {props.opsResult.opsCodes.length} | {opsCode.label}
                <Popup className={"ops-popup"} trigger={<Icon circular name='info circle' />} wide='very'>
                    <span>{opsCode.text}</span>
                </Popup>
                | Trefferwahrscheinlichkeit {(opsCode.score * 100).toFixed(1)}%
            </Accordion.Title>
            <StyledRatingOption OpsCode={opsCode.label} analysisId={props.opsResult.analysisPrimaryKey}/>
            
            <Accordion.Content className={"accordion-content"} active={activeIndex === opsCode.id}>
                <p className={"word-annotation-title"}>Nachweisdarstellung:</p>
                <WordAnnotationContainer className={"original-text-container"} originalText={props.opsResult.body} wordAnnotations={opsCode.wordAnnotations} />
            </Accordion.Content>
        </div>);
               
        

        
        
    });
    
    
    return (
            <Accordion className={props.className} inverted exclusive={false}>
                {OPSCodeAccordionItems}
            </Accordion>
        );
}