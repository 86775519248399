import React, {useEffect, useState, Fragment} from 'react';
import axios from 'axios';
import {List} from "semantic-ui-react";
import {User} from "../models/user";
import HeaderBar from "./HeaderBar";
import agent from "../api/agent";
import {Route, useHistory} from "react-router-dom";
import HomePage from "../../features/home/HomePage";
import SignupPage from "../../features/signup/SignupPage";
import AnalysisPageCookies from "../../features/Analysis/AnalysisPage";
import {StyledAnalysisPage} from "../../features/Analysis/AnalysisPage.style";
import {StyledResultPage} from "../../features/Result/ResultPage.style";
import {GlobalStyles} from "./GlobalStyles.styles";

import {Cookies, useCookies, withCookies} from "react-cookie";
import AnalysisPage from "../../features/Analysis/AnalysisPage";
import SignupPageCookies from "../../features/signup/SignupPage";
import {StyledHeaderBar} from "./HeaderBar.styles";
import {styledHomePage} from "../../features/home/HomePage.style";
import {StyledSignupPage} from "../../features/signup/SignupPage.style";

import {StyledConfirmPage} from "../../features/ConfirmPage.style";
import ConfirmPage from "../../features/ConfirmPage";
import {StyledCookieConsent} from "../../features/CookieConsent.style";
import {StyledCookiePolicy} from "../../features/CookiePolicy/CookiePolicy.style";


function App() {
    
  const [cookies, setCookie, removeCookie] : [Cookies, Function, Function] = useCookies(['rico-public-demo']);
  const [originalText, setOriginalText] = useState("");

    const history = useHistory();
    function navigateTo(path : string){
        history.push(`/${path}`);
    }
  
  return (
    <Fragment>
        <GlobalStyles />
        <StyledCookieConsent />
        <div className={"page-container"}>
            <div className={"content-wrap"}>
            <StyledHeaderBar />
            <Route exact path="/"  component={styledHomePage}/>
                <Route path='/signup'> <StyledSignupPage/> </Route>
                <Route path='/analysis'><StyledAnalysisPage setOriginalText={setOriginalText}/></Route>
                <Route path='/confirm/:uuid'><StyledConfirmPage /></Route>
                <Route path='/result'><StyledResultPage originalText={originalText}/></Route>
                <Route path='/cookiepolicy'><StyledCookiePolicy /></Route>
            </div>    
            <div className={"footer-container"}>
                <div className={"footer-content"}>
                    © BY GSG GMBH - HOHENZOLLERNRING 48 - 50672 KÖLN&nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;<a href={"https://gsg-online.com/impressum/"} target={"_blank"}>IMPRESSUM</a>&nbsp;&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;&nbsp; <a href={"https://gsg-online.com/datenschutz/"} target={"_blank"}>DATENSCHUTZ</a>
                </div>
            </div>
        </div>

    </Fragment>
  );
}

export default withCookies(App);
