import { useHistory, useParams } from 'react-router-dom'
import {useEffect, useState} from "react";
import agent from "../app/api/agent";
import {Cookies} from "react-cookie";

interface ConfirmPageProps {
    className? : string;
}


export default function ConfirmPage(props : ConfirmPageProps){
    interface RouterParams {
        uuid : string;
    }

    const [userMessage, setUserMessage] = useState("");
    let params : RouterParams= useParams();
   
    const history = useHistory();
    function navigateTo(path : string){
        history.push(`/${path}`);
    }
    function redirect() {
        setTimeout(() => {
            navigateTo("analysis")
        }, 5000)
    }
    
    useEffect(()=>{
        // call api
        agent.Users.confirm(params.uuid).then(()=> {
            agent.Users.authorize(params.uuid).then((response) => {
                if(response.data.isConfirmed){
                    setUserMessage("Die E-Mail-Bestätigung war erfolgreich. Sie werden automatisch in 5 Sekunden weitergeleitet.")
                    redirect();
                } else {
                    setUserMessage("Die E-Mail-Adresse konnte nicht bestätigt werden. Sie werden automatisch in 5 Sekunden weitergeleitet.")
                    redirect();
                }

                
            });
            // notify user


            // navigate to Analysis
        });

        
        
    }, []);
    
    return(<div className={props.className}>{userMessage}</div>);
}