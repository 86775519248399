import styled from "styled-components";
import CookieConsentCookies from "./CookieConsent";
import {
    device,
    primaryFontColor,
    primarySymbolColor,
    secondaryFontColor,
    secondarySymbolColorHover
} from "../app/layout/GlobalStyles.styles";

export const StyledCookieConsent = styled(CookieConsentCookies)`

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0,0.5);
    z-index: 9998;
    font-family: Futura Book !important;
    
    .cookie-container{
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background-color: ${secondaryFontColor};
        color: ${primaryFontColor};
        padding: 20px;
        max-width: 468px;
    }
    
    .cookie-consent-description{
        line-height: 1.5rem;
        font-size: 1.2rem;
        font-family: Futura Book;
        margin-bottom: 30px;
        
    }
    
    input[type="checkbox"]{
        border-radius: 0 !important;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        cursor: pointer
    }
    
    label {
        display: block;
        margin-bottom: 5px;
        font-size: 1.2rem;
    }
    
    .accept-all-button {
        width: 100%;
        display: block;
        margin: 15px 0 20px 0;
        background-color: transparent !important;
        border: 1px solid ${primarySymbolColor} !important;
        color: ${primarySymbolColor} !important;
        font: normal normal normal 1.2rem Futura Book !important;
        text-transform: none !important;
    }
    
    .non-styled-button{
        background-color: transparent;
        border: none;
        width: 50%;
        color: ${primaryFontColor};
        font: normal normal normal 1.2rem Futura Book !important;
        padding:10px;
    }
    
    .accept-all-button:hover {
        background-color: #222222 !important;
        text-decoration: underline ${primarySymbolColor};
    }
    .non-styled-button:hover{
        background-color: #222222;
        padding:10px;
        text-decoration: underline ${primaryFontColor};
    }
    
    a{
        color: ${secondarySymbolColorHover};
        text-decoration: underline ${secondarySymbolColorHover};
    }
    
    button{
        cursor: pointer
    }
    
    @media ${device.phone} {
        .non-styled-button{
            font-size: 0.8rem !important;
        }
    }
`;