import React from 'react';
import {Button, Checkbox, Form, Header, Label, Segment} from "semantic-ui-react";
import {Formik, Field, withFormik, ErrorMessage} from "formik";
import yup from 'yup';
import FormikControl from "./FormikControl";
import * as Yup from "yup";
import {Cookies, withCookies} from "react-cookie";
import agent from "../../../app/api/agent";
import {useHistory} from "react-router-dom";
import {primaryButton} from "../../../app/layout/GlobalStyles.styles";

interface LoginFormProps {
    cookies : Cookies
    resultDisclaimer : boolean;
    showDisclaimer: Function;
}
interface LoginData {
    email : string;
    privacyPolicy : boolean;
}
function LoginForm(props : LoginFormProps){
    const initialValues : LoginData = {
        email: '',
        privacyPolicy: false
    };

    const validationSchema = Yup.object().shape({
        email:Yup.string().email("Ungültiges E-Mail Format").required("E-Mail erforderlich"),
        privacyPolicy: Yup.bool().oneOf([true], 'Zustimmung der Datenschutzrichtlinie erforderlich.')
    });

    const history = useHistory();
    function navigateTo(path : string){
        history.push(`/${path}`);
    }
    
    const onSubmit = (values: LoginData) => {

        // Check if Disclaimer has been accepted
        if(props.resultDisclaimer == false){
            props.showDisclaimer(true);
            return;
        }
        executeLogin(values.email);
    };
    
    
    function executeLogin(email : string){
        agent.Users.uuid(email).then((response) =>{
            let currentDate = new Date();
            let monthFutureDate = new Date(currentDate);
            monthFutureDate.setDate(monthFutureDate.getDate() + 30)
            props.cookies.set('user-token', response.data, {expires : monthFutureDate});
            
            navigateTo('analysis');
        });
    }
    
    return(
        <div className={"login-container"}>
            <h2>Login – nochmal überraschen lassen</h2>
            <Formik validateOnChange={false} validateOnBlur={false} inverted initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {formik => {
        
                    return <Form onSubmit={formik.handleSubmit}>
                        <FormikControl className={"text-input"} control='input' type='email' label={<span>E-Mail Adresse<span className={"orange"}> *</span></span>} name='email'/>
                        <FormikControl control='checkbox' type='checkbox' label={(<label className={"privacy-policy-label"} htmlFor="privacyPolicy"> Ich stimme den <a href={"https://gsg-online.com/datenschutz/"} target={"_blank"}>Datenschutzrichtlinien</a> dieser Seite zu <span
                            className={"orange"}> *</span></label>)} name='privacyPolicy' />
                        <Button className={primaryButton}  type='submit'>Anmelden</Button>
                    </Form>
                }}
            </Formik>
        </div>
    );
    
}

let LoginFormCookies =  withCookies(LoginForm)
export default LoginFormCookies;

