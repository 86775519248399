import OPSCodes from "./OPSCodes";
import styled from "styled-components";
import {primaryFontColor, secondaryFontColor} from "../../app/layout/GlobalStyles.styles";

export const StyledOPSCodes = styled(OPSCodes)`
    .accordion-title{
        font: normal normal normal 1.5rem Futura Book !important;
        letter-spacing: 0px !important;
        color: ${primaryFontColor} !important;
        margin-bottom: 6px;
        display: inline-block !important;
    }
    
    .word-annotation-title{
        font: normal normal normal 1rem Futura Light;
        letter-spacing: 1.28px;
        text-transform: uppercase;
    }
    
    .original-text-container{
        background-color: ${primaryFontColor};
        color: ${secondaryFontColor};
        font: normal normal normal 22px/36px Futura Book;
        letter-spacing: 0px;
        padding: 25px 66px 27px 35px;
        max-height: 380px;
        overflow: scroll;
        white-space: pre-wrap;
    }
    .original-text-container::-webkit-scrollbar{
        width: 6px;
    }
    
    .original-text-container::-webkit-scrollbar-track {
        background-color: ${primaryFontColor};
    }
    
    .original-text-container::-webkit-scrollbar-thumb {
        background-color: #9EA1A4;
    }
    

   
    .original-text-container span{
        color: ${secondaryFontColor};
        background-color: #FFC107;
        text-decoration underline;
    }
`;