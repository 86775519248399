import React, {useEffect, useState} from 'react';
import {NavLink, useHistory} from "react-router-dom";
import agent from "../../app/api/agent";
import {User} from "../../app/models/user";
import { withCookies, Cookies } from 'react-cookie';
import {primaryButton} from "../../app/layout/GlobalStyles.styles";
import {Formik, Field, withFormik, ErrorMessage} from "formik";
import {Container, Button, Checkbox, Form, Header, Label, Segment} from "semantic-ui-react";
import FormikControl from "../signup/components/FormikControl";
import * as Yup from "yup";
import {AxiosResponse} from "axios";
import {UserAuthorizationData} from "../../app/models/UserAuthorizationData";




interface AnalysisPageProps {
    className? : string;
    cookies : Cookies;
    setOriginalText : Function;
}

interface AnalysisData {
    analysisText : string;
}

function AnalysisPage(props : AnalysisPageProps){
    const [userMessage, setUserMessage] = useState("");
    const [authorized, setAuthorized] = useState(false);
    const [remainingAnalyses, setRemainingAnalyses] = useState(5);
    
    
    const initialValues : AnalysisData = {
        analysisText : ""
    };

    const validationSchema = Yup.object().shape({
        analysisText:Yup.string().required("OP-Bericht erforderlich").min(1000, "Bitte geben Sie mindestens 1000 Zeichen ein.").test("past-analyses-exceed-limit", "Sie können nicht mehr als 5 OP-Bericht-Analysen pro Stunde durchführen.", function () {
            return new Promise(async (resolve, reject) => {
                // Check user had more than 5 analyses in the last hour
                try{
                    let userToken = props.cookies.get("user-token");
                    if (userToken) {
                        agent.Users.analysisCountOfHour(userToken).then((response) => {
                            if(response.data < 5) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                            
                        });
                        
                    }
                } catch (e) {
                    resolve(false);
                }

            });
        })
        // check if user can analyse
    });

    const history = useHistory();
    function navigateTo(path : string){
        history.push(`/${path}`);
    }
    
    function redirect() {
        setTimeout(() => {
            navigateTo("signup")
        }, 5000)
    }
    useEffect(() => {
        // check authorization by comparing cookies
        let userToken = props.cookies.get("user-token")
        if(userToken){
            (async () => {
                agent.Users.authorize(userToken).then(
                    (response) => {
                        let currentUserMessage : string = "";
                        
                        if(!response.data.userExists){
                            currentUserMessage = "Die eingegebene E-Mail-Adresse ist nicht registriert. Bitte registrieren Sie sich.";
                        } else if(response.data.isBlockedByDomain){
                            currentUserMessage = "Dieser E-Mail-Anbieter wurde blockiert.";
                        } else if(response.data.isBlocked){
                            currentUserMessage = "Die eingegebene E-Mail-Adresse wurde blockiert.";
                        } else if(!response.data.isConfirmed){
                            currentUserMessage = "Bitte bestätigen Sie Ihre E-Mail-Adresse, indem Sie auf den versandten Bestätigungslink klicken.";
                        }
                        
                        if(currentUserMessage !== "") {
                            setAuthorized(false);
                            setUserMessage(currentUserMessage + " Sie werden automatisch in 5 Sekunden weitergeleitet.")
                            redirect();
                        } else {
                            setAuthorized(true);
                            agent.Users.analysisCountOfHour(userToken).then((response) => {
                                setRemainingAnalyses(5- (response.data));
                            });

                        }
                    }
                );
            })();
        } else {

            setUserMessage("Bitte melden Sie sich an um fortzufahren. Sie werden automatisch in 5 Sekunden weitergeleitet.")
            redirect();
        }
       // response will be true or false
    }, []);

    const getTokenAsync = async (email : string) : Promise<string> => {
        let token : string = "";

        await agent.Users.uuid(email).then(
            (response) => {
                token = response.data;
                
            }
        );
        
        return token;

    };
    

    const onSubmit = (values : AnalysisData ) => {
        props.setOriginalText(values.analysisText)
        navigateTo("result");
    };

    return(
        <Container className={props.className} style={{marginTop: '7em'}}>
            <div id={"authorized"} style={{display: authorized ? "block": "none"}}>
                <div className={"analysis-intro"}>
                    <h1>OPS-KI-ANALYSE</h1>
                    <p>Kopieren Sie Ihren anonymisierten OP-Bericht in das Formular und lassen Sie sich überraschen.</p>
                </div>
                <div className={"analysis-form-container"}>
                    <Formik validateOnChange={false} validateOnBlur={false} validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
                        {formik => {
                            return <Form onSubmit={formik.handleSubmit}>
                                <FormikControl className={"analysis-textarea"} control="textarea" type="text" label="OP-BERICHT (*)" name="analysisText" placeholder={"Hallo! Hier ist RICO. Fügen Sie hier einfach Ihren anonymisierten OP-Bericht ein (mind. 1.000 Zeichen); ich übernehme die Kodierung.\n" +
                                "Und übrigens: Wenn ich auf Basis Ihrer hauseigenen Daten trainiere, wird meine Performance noch besser. Fragen Sie einfach nach einer Teststellung."}/>
                                <p className={"remaining-analyses"}>Es stehen Ihnen noch {remainingAnalyses} von 5 Textanalysen pro Stunde zur Verfügung.</p>
                                <Button className={primaryButton} type='submit'>Analysieren</Button>
                            </Form>
                            
                        }}
                    </Formik>
                </div>
            </div>
           
            <p className="error-message">{userMessage}</p>
        </Container>)
}

let AnalysisPageCookies =  withCookies(AnalysisPage)
export default AnalysisPageCookies;