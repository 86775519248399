import {Cookies, withCookies} from "react-cookie";
import {primaryButton, secondaryButton} from "../../app/layout/GlobalStyles.styles";
import {Button, Dimmer, Loader, Segment} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import { v4 as uuidv4 } from 'uuid'

import {
    JSONAnnotation,
    JSONOPSResult,
    WordAnnotation,
    OPSCode,
    OPSCodeInterface,
    OPSResult
} from "../../app/models/OPSResult";
import {mockData} from "../../app/models/MockData";
import {StyledOPSCodes} from "./OPSCodes.style";
import agent from "../../app/api/agent";
import {AxiosResponse} from "axios";
import {AISetting} from "../../app/models/AISetting";

interface ResultPageProps{
    className? : string;
    cookies : Cookies;
    originalText : string;
}


function ResultPage(props : ResultPageProps) {
    let emptyOPSResult: OPSResult = new OPSResult("0", 0, "")
    emptyOPSResult.opsCodes = [];
    const [opsResultState, setOpsResultState]: [OPSResult, Function] = useState(emptyOPSResult);

    const [loading, setLoading] = useState(false);
    const [visibleOPSCodes, setVisibleOPSCodes] = useState(0);

    const history = useHistory();
    function navigateTo(path: string) {
        history.push(`/${path}`);
    }

    useEffect(() => {
        if(props.originalText.length <= 0){
            navigateTo("analysis");
            return;
        }

        setLoading(true);
        let uuid: string = uuidv4();
        agent.Analysis.originalText(uuid, props.originalText).then((response: any) => {

            let userToken = props.cookies.get("user-token")
            agent.Analysis.analyzedText(uuid, userToken).then((response: any) => {
                let result : OPSResult = response.data;
                setOpsResultState(result);
                setLoading(false);
            })
        });
    }, []);

    useEffect(()=>{
        setVisibleOPSCodes(opsResultState.opsCodes.length);
    }, [opsResultState]);
    
    if (loading) {
        return <div  className={props.className}>
                <Loader active intermediate className={"loading-loader"}>
                    <h2>Analyse läuft</h2>
                    <p>Bitte warten...</p>
                </Loader>
        </div>
    }

    return (
        <div className={props.className}>
            <div className={"overlay"} style={{display : !loading && visibleOPSCodes<=0 ? "block" : "none"}}>
                <div className={"information-container"}>
                    <h1>INFORMATION</h1>
                    <p>{"Entweder sind die medizinischen Inhalte des analysierten Textes nicht Bestandteil der kostenlosen OPS-KI (community edition) oder es handelt sich um keinen medizinischen Text.\n\nVersuchen Sie es bitte mit einem anderen Text.\n\nDie OPS-KI (community edition) wird in regelmäßigen Zeitabständen erweitert. Bitte haben Sie dafür Verständnis, dass wir nicht 100% kostenlos zur Verfügung stellen können."}</p>
                    <div>
                        <Button onClick={() => {
                            navigateTo("analysis")
                        }} className={primaryButton}>Weiteren Text analysieren</Button>
                        <a href={"https://right-coding.com/"} target={"_blank"}><Button className={secondaryButton}>Mehr Infos zur Vollversion</Button></a>
                    </div>
                </div>
            </div>

            <div className={"result-container"} style={{display : !loading && visibleOPSCodes>0 ? "block" : "none"}}>
                <div className={"result-intro"}>
                    <h1>ERGEBNIS</h1>
                    <p><span className={"highlight"}>{visibleOPSCodes}</span> {visibleOPSCodes == 1 ? "OPS-Code konnte" : "OPS-Codes konnten"} durch die OPS-KI-Analyse detektiert werden:</p>
                </div>
                <StyledOPSCodes opsResult={opsResultState}/>
                <a href={"https://right-coding.com/"} target={"_blank"}><Button className={primaryButton}>Mehr Infos zur Vollversion</Button></a>
                <Button onClick={() => {
                    navigateTo("analysis")
                }} className={primaryButton}>Weiteren Text analysieren</Button>
            </div>
        </div>);

}

let ResultPageCookies = withCookies(ResultPage);
export default ResultPageCookies;