import React from 'react';
import Input from "./Input";
import Checkbox from './Checkbox'
import TextArea from "./TextArea";

export default function FormikControl(props: any) {
    const {control, ...rest} = props;
    switch(control){
        case 'input': return <Input {...rest}/>
        case 'checkbox': return <Checkbox {...rest}/>
        case'select':
        case'radio':
        case 'textarea': return <TextArea {...rest} />
        case 'date':
        default: return null
    }
}