import React from 'react';
import {Button, Container} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import Steps from "./Steps";
import {primaryButton} from "../../app/layout/GlobalStyles.styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {StyledRatingOption} from "../Result/RatingOption.style";

interface HomePageProps{
    className? : string;
}

export default function HomePage(props : HomePageProps){
    return(
        <div className={props.className + " home-container"}>
            <div id={"rico-header"}>
                <div id={"rico-header-text"}><h1>RICO</h1>
                    <div id={"rico-header-subtext"}><span>community edition</span></div>
                </div>
            </div>                

            <div id={"intro-container"}>
                <h2 id={"intro-text"}>In nur drei Schritten kostenlos OP-Berichte & OP-Protokolle nach relevanten operativen 5er-OPS-Codes mit KI analysieren!</h2>
                <div className={"desktop-view"}>
                    <div className={"steps-container top"}>
                        <div className={"steps-number-container left"}>
                            <div className={"steps-number"}>1</div>
                        </div>
                        <div className={"steps-space"}>
                            <div className={"horizontal-line"}></div>
                        </div>
                        <div className={"steps-number-container center"}>
                            <div className={"steps-number"}>2</div>
                        </div>
                        <div className={"steps-space"}>
                            <div className={"horizontal-line"}></div>
                        </div>
                        <div className={"steps-number-container right"}>
                            <div className={"steps-number"}>3</div>
                        </div>
                    </div>
                    <div className={"steps-container bottom"}>
                        <div className={"step-text"} id={"step-left"}>OP-Bericht auswählen<br/>+ anonymisieren</div>
                        <div className={"steps-space"}></div>
                        <div className={"step-text"} id={"step-center"} >OP-Bericht hochladen</div>
                            <div className={"steps-space"}></div>
                        <div className={"step-text"} id={"step-right"}>Durch KI überraschen lassen</div>
                    </div>
                </div>
                <div className={"mobile-view"}>
                    <div className={"steps-number"}>1</div>
                    <div className={"step-text"} id={"step-left"}>OP-Bericht auswählen<br/>+ anonymisieren</div>
                    <div className={"vertical-line"}></div>
                    <div className={"steps-number"}>2</div>
                    <div className={"step-text"} id={"step-center"} >OP-Bericht hochladen</div>
                    <div className={"vertical-line"}></div>
                    <div className={"steps-number"}>3</div>
                    <div className={"step-text"} id={"step-right"}>Durch KI überraschen lassen</div>


                </div>
                <div className={"button-container"}>
                    <Button className={primaryButton} as={NavLink} to='/signup'>Jetzt kostenlos OPS-KI testen</Button>
                </div>
            </div>
        </div>)
}