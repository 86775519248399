import React, {useState} from 'react';
import {Button, Checkbox, Form, Header, Label, Segment} from "semantic-ui-react";
import {Formik, Field,} from "formik";
import * as Yup from 'yup';
import FormikControl from "./FormikControl";
import {User} from "../../../app/models/user";
import agent from "../../../app/api/agent";
import {Cookies, withCookies} from "react-cookie";
import {useHistory} from "react-router-dom";
import {AxiosResponse} from "axios";
import {primaryButton} from "../../../app/layout/GlobalStyles.styles";

interface RegisterFormProps {
    resultDisclaimer : boolean;
    showDisclaimer: Function;
    cookies : Cookies
}

interface RegisterData {
    hospitalName: string;
    name: string;
    position: string;
    email: string;
    privacyPolicy: boolean;
}


function RegisterForm(props : RegisterFormProps) {


    const initialValues = {
        id: null,
        hospitalName: '',
        name: '',
        position: '',
        email: '',
        privacyPolicy: false
    };

    const validationSchema = Yup.object().shape({
        hospitalName: Yup.string().required("Krankenhausname erforderlich"),
        name: Yup.string().required("Name erforderlich"),
        position: Yup.string().required("Position erforderlich"),
        email: Yup.string().email("Ungültiges E-Mail Format").required("E-Mail erforderlich").test("email-already-in-use", "E-Mail wird bereits verwendet", function (email) {
            return new Promise(async (resolve, reject) => {
                // Check if email is already in use
                try{
                    let emailResponse = await agent.Users.uuid(email ?? "")
                    resolve(emailResponse.data == "NotFound");
                } catch (e) {
                    resolve(false);
                }

            });
        }),
        privacyPolicy: Yup.bool().oneOf([true], 'Zustimmung der Datenschutzrichtlinie erforderlich.')
    });

    const history = useHistory();

    function navigateTo(path: string) {
        history.push(`/${path}`);
    }

    async function onSubmit(values: RegisterData) {
        // show disclaimer if it hasn't been accepted yet
        if (props.resultDisclaimer == false) {
            props.showDisclaimer(true);
            return;
        }

        let user: User = {
            email: values.email,
            hospitalName: values.hospitalName,
            id: 0,
            isBlocked: false,
            isConfirmed: false,
            name: values.name,
            position: values.position
        };

        // only create the user if it doesnt exist on the database
        // Check if email is already being used
        let tokenResponse: AxiosResponse = await agent.Users.uuid(values.email);

        if (tokenResponse.data == "NotFound") {
            await postUser(user);
            navigateTo('analysis');
        } else {
        }
        //validationSchema.validate(initialValues);
    };

    const postUser = async (user: User): Promise<User> => {
        await agent.Users.create(user).then(async (response) => {
                await agent.Users.uuid(user.email).then(
                    (response) => {
                        // Store User Token in cookies 
                        let currentDate = new Date();
                        let monthFutureDate = new Date(currentDate);
                        monthFutureDate.setDate(monthFutureDate.getDate() + 30)
                        props.cookies.set('user-token', response.data, {expires : monthFutureDate});
                    }
                );
            }
        );


        return user;
    }

    return (
        <div className={"register-container"}>
            <h2>Neu überraschen lassen</h2>
            <Formik validateOnChange={false} validateOnBlur={false} inverted initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {formik => {

                    return <Form onSubmit={formik.handleSubmit}>
                        <div className={"register-border-container"}>


                            <FormikControl className={"text-input"} control='input' type='text'
                                           label={<span>Krankenhausname<span className={"orange"}> *</span></span>}
                                           name='hospitalName'/>
                            <FormikControl className={"text-input"} control='input' type='text'
                                           label={<span>Name<span className={"orange"}> *</span></span>} name='name'/>
                            <FormikControl className={"text-input"} control='input' type='text'
                                           label={<span>Position<span className={"orange"}> *</span></span>}
                                           name='position'/>
                            <FormikControl className={"text-input"} control='input' type='email'
                                           label={<span>E-Mail Adresse<span className={"orange"}> *</span></span>}
                                           name='email'/>
                            <FormikControl control='checkbox' type='checkbox' label={(
                                <label className={"privacy-policy-label"} htmlFor="privacyPolicy"> Ich stimme den <a href={"https://gsg-online.com/datenschutz/"} target={"_blank"}>Datenschutzrichtlinien</a> dieser Seite zu <span
                                    className={"orange"}> *</span></label>)} name='privacyPolicy'/>
                        </div>
                        <Button className={primaryButton} type='submit'>Registrieren</Button>
                    </Form>
                }}

            </Formik>
        </div>
    );
}

let RegisterFormCookies =  withCookies(RegisterForm)
export default RegisterFormCookies;