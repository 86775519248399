import axios, {AxiosResponse} from 'axios';
import {User} from "../models/user";
import {UserAuthorizationData} from "../models/UserAuthorizationData";
import {AISetting} from "../models/AISetting";
import {OPSCode} from "../models/OpsCode";
import {Rating} from "../models/Rating";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = <T> (response : AxiosResponse<T>) => response.data;

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responseBody),
}

const Users = {
    list: () => requests.get<User[]>('/users'),
    create: (user:User) => axios.post<void>('/users', user),
    authorize : (uuid : string) => axios.get<UserAuthorizationData>(`/users/authorize/${uuid}`),
    uuid : (email : string) => axios.get<string>(`/users/uuid/${email}`),
    confirm : (uuid : string) => axios.put<void>(`/users/confirm/${uuid}`),
    analysisCountOfHour : (uuid : string) => axios.get<number>(`/users/analysisCountOfHour/${uuid}`),
}

const Analysis = {
    originalText : (uniqueId : string, originalText : string) => {
        let formData = new FormData();
        formData.append("originalText", originalText);
        return axios({
            method: "post", 
            url: `/Analyses/originaltext/${uniqueId}`,
            data: formData,
            headers: {"Content-Type": "multipart/form-data" }
        });
        //return axios.post<void>(`/Analyses/originaltext/${uniqueId}`, formData)
    },
    analyzedText : (uniqueId : string, userToken : string) => axios.get<any>(`/Analyses/analyzedText/${userToken}/${uniqueId}`),
    rate: (rating: Rating) => axios.post<void>('/Analyses/rate', rating)
};

const AISettings = {
    aiSettings : () => axios.get<AISetting>("AISettings"),
    aiSettingByUser : (usertoken:string) => axios.get<AISetting>("AISettings")
};

const OpsCodes = {
    opsCodeText : (opscode : string) => axios.get<OPSCode>(`/OpsCodes/${opscode}`)
}

const agent = {
    Users,
    Analysis,
    AISettings,
    OpsCodes,
}

export default agent;