import {createGlobalStyle} from "styled-components";
import img from '../images/kopf-background.jpg';
import futuraLTPro from '../font/FuturaLTProLight.otf';
import futuraBookTtf from '../font/futura_book.ttf';
import futuraBookWoff2 from '../font/futura_book.woff2'
import futuraLightEot from '../font/futura_light.eot';
import futuraLightTtf from '../font/futura_light.ttf';
import futuraLightWoff from '../font/futura_light.woff';
import futuraLightWoff2 from '../font/futura_light.woff2';

export const secondaryFontColor : string = "#000000";
export const primarySymbolColor : string = "#F09100";
export const primarySymbolColorHover : string = "#D88200";
export const secondarySymbolColor : string = "#72797F";
export const secondarySymbolColorHover : string = "#666D72";
export const primaryFontColor : string = "#ffffff"

// classNames
export const primaryButton : string = "rico-button-primary";
export const secondaryButton : string = "rico-button-secondary";


const size = {
 tablet : "1024px", 
 phone : "540px"
};

export const device = {
   tablet: `(max-width: ${size.tablet})`, 
   phone : `(max-width: ${size.phone})`
};



export const GlobalStyles = createGlobalStyle`


    
    @font-face{
        font-family:"Futura Book";
        src:    url("${futuraBookWoff2}") format("woff2"),
                url("${futuraBookWoff2}") format("woff"),
                url("${futuraBookTtf}") format("truetype"),
                url("${futuraBookTtf}?#iefix") format("embedded-opentype");
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }
    
    @font-face{
        font-family:"Futura Light";
        src:    url("${futuraLightWoff2}") format("woff2"),
                url("${futuraLightWoff}") format("woff"),
                url("${futuraLightTtf}") format("truetype"),
                url("${futuraLightEot}?#iefix") format("embedded-opentype");
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    * {
        color: ${primaryFontColor};
    }
    
    #root{
    position: relative;
    min-height: 100%;
    }
    html{
        font-size: 1em;
    }
    
    body {
        margin: 0;
        padding : 0
        box-sizing : border-box;
        position:relative;
        background-size: auto auto;
        background-position: center top;
        background-image: url(${img});
        background-repeat: no-repeat;
        background-color: ${secondaryFontColor};
        min-height: 100%;
        height: auto;
    }
    
    .highlight {
        color: ${primarySymbolColor};
        font-weight: bold;
    }
    .page-container {
        max-width: 1528px;
        position: relative;
        min-height: 100vh;
        margin: 0 auto;
    }
    
    .content-wrap {
      padding-bottom: 2rem;    /* Footer height */
    }
    
    .${primaryButton} {
        background-color: ${primarySymbolColor} !important;
        border : none !important;
        color: ${primaryFontColor} !important;
        text-align: center !important;
        font: normal normal normal 1.2rem Futura Book !important;
        letter-spacing: 1.2px !important;
        padding: 15px 32px !important;
        border-radius:0  !important;
        text-transform: uppercase !important;
    }
   
    
    .${primaryButton}:hover{
        background-color: ${primarySymbolColorHover} !important;
    }
    
    .${primaryButton}:active{
        transform: scale(0.98);
    }
    
    
    .${secondaryButton} {
        background-color: ${secondarySymbolColor} !important;
        border : none !important;
        color: ${primaryFontColor} !important;
        text-align: center !important;
        font: normal normal normal 1.2rem Futura Light !important;
        letter-spacing: 1.2px !important;
        padding: 15px 32px !important;
        border-radius:0  !important;
        text-transform: uppercase !important;
    }
    
    .${secondaryButton}:hover{
        background-color: ${secondarySymbolColorHover} !important;
    }
    
    .${secondaryButton}:active{
        transform: scale(0.98);
    }
    
    
    
    .footer-container{
          position: absolute;
          bottom: 0;
          text-align: right;
          padding-right: 20px;
          vertical-align: bottom;
          width: 100%;
          height: 2rem;   
    }
    
    .footer-content{
    font-size: .8rem;
    }
    
    .footer-content a{
        color: ${primaryFontColor};
        text-decoration: underline ${primaryFontColor};
    }
    
    .rating-text-input{
        border-radius:0  !important;
        display: block;
        box-sizing: border-box !important;
        height: 34px !important;
        margin-top: 9px 0 !important;
        flex-grow: 1;
        border:none !important;
        border-left: 1px solid #C9C9C9 !important;
        border-bottom: 1px solid #C9C9C9 !important;
        border-top: 1px solid #C9C9C9 !important;
        bottom-right: none !important;
    }
    

    
    .rating-label {
        font: normal normal normal 1rem Futura Book;
        color: ${secondaryFontColor};
        padding-bottom: 10px;
        display: block;
        white-space: pre-line;
    }
    
    .rating-input-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    
    
    .rating.${primaryButton} {
        padding: 8px 0 !important;
        box-sizing: border-box !important;
        height: 34px !important;
        margin-top: 9px 0 !important;
        width:42px;
        border-right: 1px solid #C9C9C9 !important;
        border-top: 1px solid #C9C9C9 !important;
        border-bottom: 1px solid #C9C9C9 !important;
        background-color: white !important;
       
        *{
        color: #C9C9C9 !important;
        }
    }
    
    .rating-popup {
        margin-left: 30px !important;
        background-color: ${primaryFontColor} !important;
    }
    
    .ops-popup span {
        font: normal normal normal 1rem Futura Book;
        color: ${secondaryFontColor};
    }
    
    .submit-arrow-button{
        margin: auto;

    }
    
        .rating-text-input:focus{
        border-left: 1px solid ${primarySymbolColor} !important;
        border-top: 1px solid ${primarySymbolColor} !important;
        border-bottom: 1px solid ${primarySymbolColor} !important;
        border-right: none !important;
    }
    
    .rating-text-input:focus + .rating.${primaryButton}{
        border-left: none !important;
        border-top: 1px solid ${primarySymbolColor} !important;
        border-bottom: 1px solid ${primarySymbolColor} !important;
        border-right: 1px solid ${primarySymbolColor} !important;
        background-color: ${primarySymbolColor} !important ; 
        *{
        color: white !important;
        }
    }
    
    @media ${device.phone} {
        .footer-content{
        font-size: .7rem;
        }
    }
`;