export interface JSONOPSResult {
    analysisPrimaryKey : number;
    body : string;
    annotations : JSONAnnotation[]
    uniqueId: string;
}

export interface JSONAnnotation {
    annotationType: string;
    annotationId: string;
    begin?: number;
    end?: number;
    id?: number;
    entityType?: string;
    label?: string;
    source?: string;
    score?: number;
    fileName?: string;
}

export interface OPSResultInterface {
    analysisPrimaryKey: number;
    body : string;
    opsCodes : OPSCodeInterface[];
    uniqueId: string;
}

export interface OPSCodeInterface {
    id?: number;
    annotationType: string;
    annotationId: string;
    label: string;
    source: string;
    score: number;
    wordAnnotations: WordAnnotationInterface[];
    text? : string;
}

export interface WordAnnotationInterface {
    annotationType: string;
    annotationId: string;
    begin : number;
    end : number;
    id : number;
    entityType : string;
    source: string;
    score: number;
}


export class OPSResult implements OPSResultInterface {
    opsCodes: OPSCodeInterface[] = [];
    body: string;
    uniqueId: string;
    analysisPrimaryKey : number;
    
    constructor(uniqueId : string, analysisPrimaryKey:number, body : string) {
        this.body = body;
        this.uniqueId = uniqueId;
        this.analysisPrimaryKey = analysisPrimaryKey;
    }
}

export class OPSCode implements  OPSCodeInterface{
    id?: number;
    annotationId: string;
    annotationType: string;
    wordAnnotations: WordAnnotationInterface[] = [];
    label: string;
    score: number;
    source: string;
    text?: string;
    
    constructor(annotationId : string, annotationType: string, label: string, score : number, source: string) {
        this.annotationId = annotationId;
        this.annotationType = annotationType;
        this.label = label;
        this.score = score;
        this.source = source;
    }
    
}

export class WordAnnotation implements WordAnnotationInterface{
    annotationId: string;
    annotationType: string;
    begin: number;
    end: number;
    entityType: string;
    id: number;
    score: number;
    source: string;

    constructor(annotationId : string, annotationType: string, begin: number, end: number, entityType: string, id: number, score : number, source: string) {
        this.annotationId = annotationId;
        this.annotationType = annotationType;
        this.begin = begin;
        this.end = end;
        this.entityType = entityType;
        this.id = id;
        this.score = score;
        this.source = source;
    }
}