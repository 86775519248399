import RatingOption from "./RatingOption";
import styled from "styled-components";
import {primaryFontColor, primarySymbolColor} from "../../app/layout/GlobalStyles.styles";

export const StyledRatingOption = styled(RatingOption)`
    display: inline-block;

    .thumb.up.selected{
        * {
                color: #0fe000 !important;
        }
    }
    
    .thumb.down.selected{
        * {
                color: #e00000 !important;
        }
    }
    
    .thumb.up.default{
        * {
                color: #549150 !important;
        }
    }
    
    .thumb.down.default{
        * {
                color: #974949 !important;
        }
    }
    
    .thumb.down{
        margin-bottom: -7px;
    }
    
    .thumb {
        margin-left: 32px;
    }
    
    button {
        background-color: transparent;
        border: none;
        font-size: 2rem;
    }
    
    button:hover{
        cursor: pointer;
    }
    

    
    

    
   
`; 