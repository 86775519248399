import {Cookies, withCookies} from "react-cookie";
import {useEffect} from "react";

interface CookiePolicyProps
{
    className? : string;
    cookies : Cookies
}
function CookiePolicy(props: CookiePolicyProps) {
    useEffect(()=>{
        
    }, []);
    
    return(<div className={props.className}>
        <p>Diese Cookie-Richtlinie wurde zuletzt am September 08, 2021 aktualisiert und gilt für Bürger und rechtmäßige ständige Einwohner des Europäischen Wirtschaftsraums.</p>


        <h2>1. Einführung</h2>
        <p>Unsere Website, https://autonomes-kodieren.de/ (im folgenden: “Die Website”) verwendet Cookies und ähnliche Technologien (der Einfachheit halber werden all diese unter “Cookies” zusammengefasst). Cookies werden außerdem von uns beauftragten Drittparteien platziert. In dem unten stehendem Dokument informieren wir dich über die Verwendung von Cookies auf unserer Website.</p>

        <h2>2. Was sind Cookies</h2>
        <p>Ein Cookie ist eine einfache kleine Datei, die gemeinsam mit den Seiten einer Internetadresse versendet und vom Webbrowser auf dem PC oder einem anderen Gerät gespeichert werden kann. Die darin gespeicherten Informationen können während folgender Besuche zu unseren oder den Servern relevanter Drittanbieter gesendet werden.</p>

        <h2>3. Was sind Skripte?</h2>
        <p>Ein Script ist ein Stück Programmcode, das benutzt wird, um unserer Website Funktionalität und Interaktivität zu ermöglichen. Dieser Code wird auf unseren Servern oder auf deinem Gerät ausgeführt.</p>

        <h2>4. Was ist ein Web Beacon?</h2>
        <p>Ein Web-Beacon (auch Pixel-Tag genannt), ist ein kleines unsichtbares Textfragment oder Bild auf einer Website, das benutzt wird, um den Verkehr auf der Website zu überwachen. Um dies zu ermöglichen werden diverse Daten von dir mittels Web-Beacons gespeichert.</p>

        <h2>5. Cookies</h2>
        <h3>5.1 Technische oder funktionelle Cookies</h3>

        <p>Einige Cookies stellen sicher, dass Teile unserer Website richtig funktionieren und deine Nutzervorlieben bekannt bleiben. Durch das Platzieren funktionaler Cookies machen wir es dir einfacher unsere Website zu besuchen. Auf diese Weise musst du bei Besuchen unserer Website nicht wiederholt die gleichen Informationen eingeben, oder deine Gegenstände bleiben beispielsweise in deinem Warenkorb bis du bezahlst. Wir können diese Cookies ohne dein Einverständnis platzieren.</p>

        <h3>5.2 Analytische Cookies</h3>

        <p>Wir verwenden analytische Cookies, um das Website-Erlebnis für unsere Nutzer zu optimieren. Mit diesen analytischen Cookies erhalten wir Einblicke in die Nutzung unserer Website. Wir bitten um deine Erlaubnis, analytische Cookies zu setzen.</p>

        <h2>6. Platzierte Cookies</h2>
        <h3>funktionelle Cookies</h3>
        <p>Wir verwenden Cookies, um die Funktionalität der Webseite zu gewährleisten</p>
        <h4>Gegenstand der Untersuchung</h4>
        <p>
            Name: user-token <br />
            Beibehaltung: 30 Tage <br />
            Funktion: Wird für die beibehaltung der Benutzer-Sitzung benötigt.<br />
            <br />
            Name: functional-cookies <br />
            Beibehaltung: 30 Tage <br />
            Funktion: Überprüft, ob Cookies gesetzt werden können. <br />
            <br />
            Name: statistics-cookies <br />
            Beibehaltung: 30 Tage <br />
            Funktion: Überprüft, ob Cookies für Website-Statistiken gesetzt werden können. <br />
        </p>

        <h3>Google Analytics</h3>
        <p>Wir verwenden Google Analytics für Website-Statistiken. Für weitere Informationen, bitte die <a href="https://policies.google.com/privacy" target={"_blank"}>Google Analytics Datenschutzerklärung</a> lesen.</p>
        <h4>Gegenstand der Untersuchung</h4>
        <p>
            Name: _ga_* <br />
            Beibehaltung: 2 Jahre
            Funktion: Ermitteln von Website-Statistiken.
        </p>

        <h2>7. Zustimmung</h2>
        Wenn du unsere Website das erste Mal besuchst, zeigen wir dir ein Pop-Up mit einer Erklärung über Cookies. Sobald du auf „Einstellungen speichern“ klickst, gibst du uns dein Einverständnis, alle von dir gewählten Kategorien von Cookies und Plugins wie in dieser Cookie-Erklärung beschrieben zu verwenden. Du kannst die Verwendung von Cookies über deinen Browser deaktivieren, aber bitte beachte, dass unsere Website dann unter Umständen nicht richtig funktioniert.

        <h3>7.1 Verwalte deine Zustimmungseinstellungen</h3>

        <h2>8. Deine Rechte in Bezug auf persönliche Daten</h2>
        <p>Du hast in Bezug auf deine persönlichen Daten die folgenden Rechte:</p>

        <p>Du hast das Recht zu wissen, warum deine persönlichen Daten gebraucht werden, was mit ihnen passiert und wie lange diese verwahrt werden.
            Zugriffsrecht: Du hast das Recht deine uns bekannten persönliche Daten einzusehen.
            Recht auf Berichtigung: Du hast das Recht wann immer du wünscht, deine persönlichen Daten zu ergänzen, zu korrigieren sowie gelöscht oder blockiert zu bekommen.
            Wenn du uns dein Einverständnis zur Verarbeitung deiner Daten gegeben hast, hast du das Recht dieses Einverständnis zu widerrufen und deine persönlichen Daten löschen zu lassen.
            Recht auf Datentransfer deiner Daten: Du hast das Recht, alle deine persönlichen Daten von einem Kontrolleur anzufordern und in ihrer Gesamtheit zu einem anderen Kontrolleur zu transferieren.
            Widerspruchsrecht: Du kannst der Verarbeitung deiner Daten widersprechen. Wir entsprechen dem, es sei denn es gibt berechtigte Gründe für die Verarbeitung.
            Um diese Rechte auszuüben kontaktiere uns bitte. Bitte beziehe dich auf die Kontaktdaten am Ende dieser Cookie-Erklärung. Wenn du eine Beschwerde darüber hast, wie wir deine Daten behandeln, würden wir diese gerne hören, aber du hast auch das Recht diese an die Aufsichtsbehörde (der Datenschutzbehörde) zu richten.</p>

        <h2>9. Aktivierung/Deaktivierung und Löschen von Cookies</h2>
        <p>Du kannst deinen Internetbrowser verwenden um automatisch oder manuell Cookies zu löschen. Du kannst außerdem spezifizieren ob spezielle Cookies nicht platziert werden sollen. Eine andere Möglichkeit ist es deinen Internetbrowser derart einzurichten, dass du jedes Mal benachrichtigt wirst, wenn ein Cookie platziert wird. Für weitere Information über diese Möglichkeiten beachte die Anweisungen in der Hilfesektion deines Browsers.</p>

        <p>Bitte nimm zur Kentniss, dass unsere Website möglicherweise nicht richtig funktioniert wenn alle Cookies deaktiviert sind. Wenn du die Cookies in deinem Browser löscht, werden diese neuplatziert wenn du unsere Website erneut besuchst.</p>

        <h2>Kontaktdaten</h2>
        <p>Für Fragen und/oder Kommentare über unsere Cookie-Richtlinien und diese Aussage kontaktiere uns bitte mittels der folgenden Kontaktdaten:</p>

        GSG GmbH <br />
        Hohenzollernring 48, 50672 Köln <br />
        Deutschland <br />
        Website: <a href="https://gsg-online.com/" target={"_blank"}> https://gsg-online.com/ </a> <br />
        E-Mail: info@gsg-online.com <br />
        Telefonnummer: + 49 231 28 66 11 00 <br />
    </div>);
}

let CookiePolicyCookies = withCookies(CookiePolicy);
export default CookiePolicyCookies;