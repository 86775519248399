import styled from "styled-components";
import CookiePolicyCookies from "./CookiePolicy";
import {primarySymbolColor, primarySymbolColorHover} from "../../app/layout/GlobalStyles.styles";

export const StyledCookiePolicy = styled(CookiePolicyCookies)`
        {
            padding: 50px 0;
        }
        h1, h2, h3, h4, h5, h5, p, a{
            font-family: Futura Book !important;
            font-weight: normal !important;
        }
        
        a:link{
            color: ${primarySymbolColor};
        }
        a:visited {
            color: ${primarySymbolColorHover};
        }
        
        a:hover {
            color: ${primarySymbolColorHover};
        }
`;