import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import agent from "../../app/api/agent";
import {Rating} from "../../app/models/Rating";
import {Button, Form, Input, Popup} from "semantic-ui-react";
import FormikControl from "../signup/components/FormikControl";
import {primaryButton} from "../../app/layout/GlobalStyles.styles";
import {Formik} from "formik";
import styled from "styled-components";

interface RatingProps{
    className? : string;
    analysisId : number;
    OpsCode : string;
}

export default function RatingOption(props: RatingProps){

    const [liked, setLiked] : [boolean, Function] = useState(false);
    const [disliked, setDisliked] : [boolean, Function] = useState(false);
    const [isOpen, setIsOpen] : [boolean, Function] = useState(false);
    
    useEffect(()=>{
        
    });


    function rate(rated : number, suggestion : string = ""){
        let rating : Rating = {
            id: 0,
            analysisId: props.analysisId,
            rated: rated,
            opsCode: props.OpsCode,
            suggestion : suggestion
        }
        agent.Analysis.rate(rating).then((response) => {
        });
    }
    
    function handleThumpsUp(e : any){
        if(liked){
            return;
        }
        setLiked(true);
        setDisliked(false);
        rate(1);
    }

    function handleThumpsDown(e : any){
        if(disliked){
            return;
        }
        setDisliked(true)
        setLiked(false);
        rate(0);
    }


    interface FormData {
        opsCode : string;
    }
    const initialValues : FormData = {
        opsCode : ""
    };


    const onSubmit = (values : FormData ) => {
        setDisliked(true)
        setLiked(false);
        setIsOpen(false);
        rate(0, values.opsCode);
    };
    
    const handleClose = () => {
      setIsOpen(false);
    };
    
    const handleOpen = () => {
        setIsOpen(true);
    }

    
    return(<div className={props.className}>
        <button onClick={handleThumpsUp}><FontAwesomeIcon className={"thumb up " + (liked ? "selected" : "default")} icon={faThumbsUp} style={{}} /></button>
        <Popup className={"rating-popup"} wide={"very"} on={"click"} open={isOpen} onClose={handleClose} onOpen={handleOpen} trigger={<button><FontAwesomeIcon className={"thumb down "  + (disliked ? "selected" : "default")} icon={faThumbsDown} /></button>}>
            <div>
                <Formik validateOnChange={false} validateOnBlur={false} initialValues={initialValues} onSubmit={onSubmit}>
                    {formik => {
                        return <Form onSubmit={formik.handleSubmit}>
                            <label className={"rating-label"} htmlFor={"opsCode"}>{"Helfen Sie RICO die Textanalyse zu verbessern.\nWie lautet der richtige OPS-Code?"}</label>
                            <div className={"rating-input-container"}>
                                <input className={"rating-text-input"} type='text' name='opsCode' value={formik.values.opsCode} onChange={formik.handleChange} placeholder={"Richtigen OPS-Code eingeben"}/>
                                <Button className={"rating " +primaryButton} type='submit'><FontAwesomeIcon className={"submit-arrow-button"} icon={faArrowRight} style={{}} /></Button>
                            </div>
                        </Form>

                    }}
                </Formik>
            </div>
        </Popup>
        
        
    </div>);
}