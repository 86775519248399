import React, {useEffect, useState} from 'react';
import {Button, Confirm, Container} from 'semantic-ui-react';

export interface DisclaimerProps {
    className? : string;
    result : boolean;
    setResult : Function;
    open : boolean;
    setOpen : Function;
}

export default function Disclaimer(props : DisclaimerProps){
    
    const initialCall = true;
    useEffect(() => {
        show();
    }, [initialCall]);
    
    function show(){
        props.setOpen(true);
    }
    
    function handleCancel(){
        props.setResult(false);
        props.setOpen(false);
        // set parent prop s
    }

    function handleConfirm(){
        props.setResult(true);
        props.setOpen(false);

    }
    
    return (
        <Confirm className={props.className + " disclaimer-container"}
            open={props.open}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            header={'DISCLAIMER RICO OPS-KI\ncommunity edition'}
            content={"Die RICO OPS-KI community edition ist eine auf den operativen OPS-Bereich beschränkte Text-Analyse-Engine. Inhaltliche Kodierlogiken werden nicht plausibilisiert. Die Vollversion mit allen Logiken und OPS-Bereichen ist in der Kodierunterstützungssoftware RICO implementiert.\n\nEs stehen 5 Textanalysen pro Stunde zur Verfügung.\n\nDie OPS-KI community edition ist für medizinische Texte entwickelt. Nicht-medizinische Texte können zu Ergebnis-Artefakten führen.\n\nDie OPS-KI community edition wird sukzessive um weitere OPS-Bereiche ergänzt.\n\nGSG gibt keine Gewähr auf die Richtigkeit und Vollständigkeit der RICO OPS-KI community edition Kodiervorschläge."}
            cancelButton="Ablehnen"
            confirmButton="Akzeptieren"
        >
        </Confirm>
    )
}